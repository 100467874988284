import { apiClient, veryEngineApiClient } from "@/services/HttpService";

// 设置密码-发送短信验证码
export function sendMessageCodeInPwd(params) {
  return veryEngineApiClient.post("/User/FindPwd", params);
}

// 绑定手机号-发送短信验证码（采用外部接口）
export function sendMessageCodeInPwds(params) {
  return veryEngineApiClient.post("/User/Captcha2BindPhone", params);
}

// 绑定邮箱-确认验证码是否正确（采用外部接口）
export function isRealTelCaptcha(params) {
  return veryEngineApiClient.post("/User/BindPhone", params);
}

// 绑定邮箱-发送短信验证码（采用外部接口）
export function sendMessageCodeInEmail(params) {
  return veryEngineApiClient.post("/User/Captcha2BindEmail", params);
}

// 绑定邮箱-确认验证码是否正确（采用外部接口）
export function isRealEmailCaptcha(params) {
  return veryEngineApiClient.post("/User/BindEmail", params);
}

// 验证码登录-发送短信验证码
export function sendMessageCodeInLogin(params) {
  return veryEngineApiClient.post("/User/Captcha2Login", params);
}

// 设置密码
export function setPassword(params) {
  return veryEngineApiClient.post("/User/ReRegister", params);
}

// 账号密码登录
export function loginByPwd(params) {
  return veryEngineApiClient.post(`/User/Login`, params);
}

// 验证码登录
export function loginByVerifyCode(params) {
  return veryEngineApiClient.post(`/User/CaptchaLogin`, params);
}

// 微信扫码登录
export function loginByScanWechat(params) {
  return veryEngineApiClient.post(`/login/wechat`, params);
}

// 第三方跳转登录获取用户信息
export function loginByTrail(params) {
  return apiClient.post(`/login/userInfo`, params);
}

// 获取图片验证码
export function fetchcaptchaImg() {
  return apiClient.get(`/captchaImg`);
}

// 设置密码
export function bindUserDetail(userId, params) {
  return apiClient.put(`/users/${userId}/bind`, params);
}

// 获取用户信息
export function fetchUserDetail() {
  return apiClient.get(`/users/base
`);
}


