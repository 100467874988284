<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="!$route.meta.keepAlive" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {},
  methods: {
    // goRelation() {
    //   scroll({
    //     top: this.$refs.footBar.$el.offsetTop,
    //     behavior: "smooth",
    //   });
    // },
  },
};
</script>

<style>
#app {
  background: rgba(0, 0, 0, 0.75);
  min-width: 1200px;
}
.container {
  min-width: 1200px;
  min-height: calc(100vh - 72px);
}
.ant-modal-confirm-title {
  font-size: 14px;
  font-weight: 300 !important;
  color: #333333 !important;
}
.ant-modal-content {
  border-radius: 18px !important;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3) !important;
}
.ant-modal {
  width: 420px !important;
}
.ant-modal .anticon {
  display: none !important;
}
.ant-modal .ant-btn {
  border: 1px solid rgb(13, 87, 213) !important;
  color: #0052d9 !important;
}
.ant-modal-footer {
  border-top: 0 !important;
  padding: 20px 16px !important;
}
.ant-modal-body {
  padding: 48px 48px 30px 48px !important;
}
.ant-modal .ant-btn-primary {
  border: 0 !important;
  color: #fff !important;
  background-color: #0052d9 !important;
}
.ant-modal {
  top: 0 !important;
}
.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0 !important;
}
div::first-letter, p::first-letter, span::first-letter, a::first-letter {
  text-transform: uppercase;
}
</style>
