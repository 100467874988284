import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "ant-design-vue/dist/antd.css";
import "nprogress/nprogress.css";
import registerDirectives from "./directive";
import {
  Layout,
  Tabs,
  Form,
  Input,
  InputNumber,
  Button,
  Icon,
  Checkbox,
  Alert,
  Menu,
  Dropdown,
  Pagination,
  Avatar,
  Breadcrumb,
  Table,
  Collapse,
  Tag,
  Divider,
  PageHeader,
  Modal,
  Row,
  Col,
  Space,
  Upload,
  Select,
  Radio,
  Tree,
  TreeSelect,
  Card,
  Empty,
  Rate,
  Spin,
  Switch,
  DatePicker,
  Descriptions,
  Badge,
  Statistic,
  List,
  ConfigProvider,
  Carousel,
  Steps,
  Timeline,
  Popconfirm,
  message,
  TimePicker,
  Progress,
  Tooltip,
} from "ant-design-vue";

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);

// 注册指令
registerDirectives(Vue);

Vue.config.productionTip = true;
Vue.config.errorHandler = function (err) {
  console.log("global", err);
};
message.config({
  top: `80px`,
});
Vue.prototype.$bus = new Vue();
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
Vue.use(Layout);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Input);
Vue.use(message);
Vue.use(InputNumber);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Alert);
Vue.use(Menu);
Vue.use(Rate);
Vue.use(Dropdown);
Vue.use(Avatar);
Vue.use(Breadcrumb);
Vue.use(Table);
Vue.use(Collapse);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(PageHeader);
Vue.use(Modal);
Vue.use(Row);
Vue.use(Col);
Vue.use(Space);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Tree);
Vue.use(TreeSelect);
Vue.use(Card);
Vue.use(Empty);
Vue.use(Spin);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(Descriptions);
Vue.use(Badge);
Vue.use(Statistic);
Vue.use(List);
Vue.use(ConfigProvider);
Vue.use(Carousel);
Vue.use(Steps);
Vue.use(Timeline);
Vue.use(Popconfirm);
Vue.use(Collapse);
Vue.use(TimePicker);
Vue.use(Progress);
Vue.use(Tooltip);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
