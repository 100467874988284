import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    routeNavTitle: "",
    beforeNavTitle: "",
    isMyPage: false,
    hasChange: false,
    checkId: "817485bf52754d8880cb97789f88141d",
    isCheckUs: false,
    checkSideMenu: "edit",
  },
  getters: {},
  mutations: {
    openVuex(state, word) {
      state.routeNavTitle = word;
      if (word !== "联系我们") {
        state.beforeNavTitle = word;
      }
    },
    checkId(state, word) {
      state.checkId = word;
    },
    checkSideMenu(state, word) {
      state.checkSideMenu = word;
    },
  },
  actions: {
    openVuex({ commit }, word) {
      commit("openVuex", word);
    },
    checkId({ commit }, word) {
      commit("checkId", word);
    },
    checkSideMenu({ commit }, word) {
      commit("checkSideMenu", word);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
