import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "../store/store";
import { loginByTrail } from "@/services/LoginService";
let xhr = new XMLHttpRequest();
//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "@/views/Login"),
  },
  // 首页
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      hasBar: true,
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "HomeIndex" */ "@/views/homeIndex"),
  },
  // 我的空间页
  {
    path: "/adm",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "Admin" */ "@/views/Admin.vue"),
    children: [
      // 轮播图配置  和  插件组合
      {
        path: "banner-and-group",
        name: "BannerAndGroup",
        component: () => import("@/views/BannerAndGroup/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  xhr.open("post", "https://utp.veryengine.cn/User/HasLogin");
  xhr.withCredentials = true;
  xhr.send();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let resp = JSON.parse(xhr.response);
      if (resp.code === "00000") {
        const data = resp.data;
        const params = {
          nickname: data.userName,
          outId: data.userId,
          wechatId: data.wechat ? data.wechat : "",
          wechatName: data.userName,
          photo: data.avatar,
          email: data.email ? data.email : "",
          mobile: data.phone ? data.phone : "",
        };
        localStorage.setItem("nickName", data.userName);
        if (params) {
          loginByTrail(params)
            .then((resp) => {
              if (resp.data.code === "SUCCESS") {
                const data = resp.data.data;
                localStorage.setItem("userId", data.userId);
                localStorage.setItem(
                  "profilePictureUrl",
                  data.profilePictureUrl
                );
                localStorage.setItem("roleIds", data.roleIds);
                localStorage.setItem("token", data.token);
                store.state.isMyPage = true;
                NProgress.start();
                next();
              } else {
                NProgress.start();
                next();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          NProgress.start();
          next();
        }
      } else {
        localStorage.removeItem("userId");
        localStorage.removeItem("nickName");
        localStorage.removeItem("token");
        store.dispatch("openVuex", "");
        store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
        store.state.isMyPage = true;
        if (to.name !== "Login") {
          next({
            replace: true,
            name: "Login",
          });
        } else {
          NProgress.start();
          next();
        }
      }
    } else if (xhr.readyState === 4 && xhr.status !== 200) {
      NProgress.start();
      next();
    }
  };
});
router.afterEach(() => {
  NProgress.done();
  // 定时器解决 部分翻译问题
  let num = 0;
  let intervalName = null;
  intervalName = setInterval(() => {
    if (num < 10) {
      translate.execute();
      num++;
    } else {
      clearInterval(intervalName);
    }
  }, 500);
});

export default router;
